// src/App.js
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import { loginRequest } from "./authConfig";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import QuickLinks from './QuickLinks';
import clientDataLogo from './clientDataLogo.png';
import crh from './crh.png';
import hrhub from './hrhub.png';
import itsupport from './itsupport.png';
import mRoomLogo from './mRoomLogo.png';
import pleo from './pleo.png';
import syn from './syn.png';
import iCompleatLogo from './iCompleatLogo.png';
import xeroLogo from './xero.png';
import streamtimeLogo from './streamtimeLogo.png';
import lloydsLogo from './lloyds.png';
import fathomLogo from './fathom.png';
import scribeLogo from './scribelogo.png';
import { Client } from "@microsoft/microsoft-graph-client";

const App = () => {
  // Variables for the API URL
  const sha512password = '3af06cb90efb810287f4279fef8063159959d66b62e9118a9221b44bb5294e3961838518697ea646e8585698096384cdaeb18c1aef3b1742fbc6a9cd4b93351c';
  const user = 'apiread';
  const company = '1';
  const version = '3.9';

  const { instance, accounts } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [search, setSearch] = useState("");
  const [jobTitle, setJobTitle] = useState(null);

  // Function to handle login
  const handleLogin = async () => {
    try {
      await instance.loginPopup(loginRequest);
    } catch (error) {
      console.error("Login failed: ", error);
    }
  };

  // Fetch the user's job title
  const fetchUserProfile = async (accessToken) => {
    const client = Client.init({
      authProvider: (done) => {
        done(null, accessToken);
      },
    });

    try {
      const user = await client.api("/me").get();
      setJobTitle(user.jobTitle || "Not set");
    } catch (error) {
      console.error("Failed to fetch user profile: ", error);
    }
  };

  // Fetch data only if the user is authenticated
  useEffect(() => {
    if (isAuthenticated) {
      const fetchData = async () => {
        try {
          const response = await fetch(
            `https://camarguegroup.synergist.cloud/jsonapi/jobs.json?modelstructure=jobslist&sha512password=${sha512password}&user=${user}&company=${company}&version=${version}`
          );
          const result = await response.json();
          setData(result.data || []);
          setFilteredData(result.data || []);
          setLoading(false);
        } catch (err) {
          setError("Failed to fetch data");
          setLoading(false);
        }
      };

      // Fetch user profile
      const fetchAccessTokenAndProfile = async () => {
        try {
          const account = accounts[0];
          if (account) {
            const tokenResponse = await instance.acquireTokenSilent({
              ...loginRequest,
              account,
            });
            await fetchUserProfile(tokenResponse.accessToken);
          }
        } catch (error) {
          console.error("Error fetching access token or user profile: ", error);
        }
      };

      fetchData();
      fetchAccessTokenAndProfile();
    }
  }, [isAuthenticated, accounts, instance]);

  // Handle search input
  useEffect(() => {
    const lowercasedSearch = search.toLowerCase();
    const filtered = data.filter(
      (item) =>
        item.jobNumberSimple.toLowerCase().includes(lowercasedSearch) ||
        item.jobClientCode.toLowerCase().includes(lowercasedSearch) ||
        item.jobDescription1stLine.toLowerCase().includes(lowercasedSearch) ||
        item.jobClientName.toLowerCase().includes(lowercasedSearch) ||
        item.jobHandlerFullName.toLowerCase().includes(lowercasedSearch) ||
        item.jobCustomField6.toLowerCase().includes(lowercasedSearch)
    );
    setFilteredData(filtered);
  }, [search, data]);

  // Define columns for the DataTable
  const columns = [
    {
      id: 'sharepointLink',
      name: 'SharePoint Link',
      selector: row => row.jobCustomField6,
      sortable: true,
      width: '60px',
      cell: row => (
        row.jobCustomField6 ? (
          <a href={row.jobCustomField6} target="_blank" rel="noopener noreferrer">
            <img
              src="shpt.png"
              alt="SharePoint Link"
              style={{ width: '20px', height: '20px', cursor: 'pointer' }}
            />
          </a>
        ) : null
      )
    },
    {
      name: 'Job Number',
      selector: row => row.jobNumberSimple,
      sortable: true,
      width: '100px',
      cell: row => (
        row.jobNumberSimple ? (
          <a href="https://camarguegroup.synergist.cloud/" target="_blank" rel="noopener noreferrer">
            {row.jobNumberSimple}
          </a>
        ) : null
      )
    },
    {
      name: 'Client Code',
      selector: row => row.jobClientCode,
      sortable: true,
      width: '120px'
    },
    {
      name: 'Description',
      selector: row => row.jobDescription1stLine,
      sortable: true
    },
    {
      name: 'Client Name',
      selector: row => row.jobClientName,
      sortable: true
    },
    {
      name: 'Job Handler',
      selector: row => row.jobHandlerFullName,
      sortable: true
    }
  ];

  if (!isAuthenticated) {
    return (
      <div className="login-container">
        <img src="logo.png" alt="Logo" className="logo-login" />
        <h1>Please sign in to access the application</h1>
        <button onClick={handleLogin} className="btn btn-primary">
          Sign In
        </button>
      </div>
    );
  }

  return (
    <div>
      <header className="app-header">
        <img src="logo.png" alt="Logo" className="logo" />
        {isAuthenticated && (
          <div>
            <span>Welcome: {accounts[0].name}, {jobTitle || "Loading..."}</span>
          </div>
        )}
      </header>

      <div className="container mt-3">

      <QuickLinks
    links={[
        // Add Xero link if jobTitle includes "Finance"
        ...(jobTitle?.includes("Finance")
            ? [
                {
                    title: 'Xero',
                    url: 'https://login.xero.com/identity/user/login?ReturnUrl=%2Fidentity%2Fconnect%2Fauthorize%2Fcallback%3Fclient_id%3Dxero_business_xeroweb_hybrid-web%26redirect_uri%3Dhttps%253A%252F%252Fgo.xero.com%252Fsignin-oidc%26response_mode%3Dform_post%26response_type%3Dcode%2520id_token%26scope%3Dxero_all-apis%2520openid%2520email%2520profile%26state%3DOpenIdConnect.AuthenticationProperties%253Dn9uVSAdZxOmsFZdo4SYy90Y0NXvoCS8jzawGZ8NAzmjK2I1R9dwe__zZ88JfbbLTiuLFKx8IG7FEK3aJLyCL9vE1A_EzkWUYeDPVfVe8j5ETd0IdnkDE-WYL3EfuEc1nImKAzFK45CXizOxAFGwLaRQaIHwlwgy0yN7G8PvRv3MedmbLnX_ZpVeyW8hifTLfx_ekt0QGXGYHi2M_vB0SG4U_QNA%26nonce%3D638461142653020714.ZTdjMzEwYWQtZDZiNS00NGRhLWJlNjUtZmQ0YThlOGNhOWU2NGRlOTIyZmMtNzQ2OC00NWI5LWJmMGYtODhjNzIxZTc2ZTk3%26x-client-SKU%3DID_NET451%26x-client-ver%3D1.3.14.0',
                    image: xeroLogo,
                },
            ]
            : []),

        // Always add Synergist link
        {
            title: 'Synergist',
            url: 'https://camarguegroup.synergist.cloud/',
            image: syn,
        },

        // Add StreamTime link if jobTitle includes "Finance"
        ...(jobTitle?.includes("Finance")
            ? [
                {
                    title: 'StreamTime',
                    url: 'https://jamesforddesignteam.app.streamtime.net/#dashboard',
                    image: streamtimeLogo,
                },
            ]
            : []),

        // Add iCompleat link if jobTitle includes "Finance", "Director", or "Chair"
        ...(jobTitle?.includes("Finance") ||
        jobTitle?.includes("Director") ||
        jobTitle?.includes("Chair")
            ? [
                {
                    title: 'iCompleat',
                    url: 'https://portal.compleat.online/',
                    image: iCompleatLogo,
                },
            ]
            : []),

        // Always add Pleo link
        {
            title: 'Pleo',
            url: 'https://app.pleo.io/login',
            image: pleo,
        },

        // Add finance-specific links if jobTitle includes "Finance"
        ...(jobTitle?.includes("Finance")
            ? [
                {
                    title: 'Lloyds',
                    url: 'https://cbonline.lloydsbank.com/PrimaryAuth/',
                    image: lloydsLogo,
                },
                {
                    title: 'Fathom',
                    url: 'https://app.fathomhq.com/login?ReturnUrl=%2fmy-companies',
                    image: fathomLogo,
                },
                {
                    title: 'Scribe',
                    url: 'https://scribeapp.io/login',
                    image: scribeLogo,
                },
            ]
            : []),

        // Always add HR Hub link
        {
            title: 'HR Hub',
            url: 'https://camargue.sharepoint.com/sites/HR_Hub/',
            image: hrhub,
        },

        // Always add Central Resources link
        {
            title: 'Central Resources',
            url: 'https://camargue.sharepoint.com/sites/CRH',
            image: crh,
        },

        // Always add Client Data link
        {
            title: 'Client Data',
            url: 'https://camargue.sharepoint.com/_layouts/15/sharepoint.aspx',
            image: clientDataLogo,
        },

        // Always add Room Booking link
        {
            title: 'Room Booking',
            url: 'https://apps.powerapps.com/play/e/default-46d4e256-b3be-4371-9b3c-480cbc8d7489/a/f95044d6-4723-4790-867e-3d05365ddfb3?tenantId=46d4e256-b3be-4371-9b3c-480cbc8d7489&source=sharebutton&sourcetime=1723220431195#',
            image: mRoomLogo,
        },

        // Always add IT Guidance link
        {
            title: 'IT Guidance',
            url: 'https://camargue.sharepoint.com/sites/HR_Hub/SitePages/ITSupport.aspx',
            image: itsupport,
        },
    ]}
/>
      </div>       

      <div className="container mt-3">
        <input
          type="text"
          className="form-control mb-3"
          placeholder="Search clients..."
          value={search}
          onChange={e => setSearch(e.target.value)}
        />
        {loading ? (
          <p>Loading...</p>
        ) : error ? (
          <p className="text-danger">{error}</p>
        ) : (
          <DataTable
            columns={columns}
            data={filteredData}
            pagination
            paginationPerPage={50}
            highlightOnHover
            pointerOnHover
            defaultSortFieldId="sharepointLink" // Matches the column's id
            defaultSortAsc={false} // Descending order
          />
        )}
      </div>
    </div>
  );
};

export default App;
