import React from 'react';
import './QuickLinks.css';

const QuickLinks = ({ links }) => {
  return (
    <div className="quick-links-container">
      {links.map((link, index) => (
        <a href={link.url} key={index} className="quick-link-box" target="_blank" rel="noopener noreferrer">
          <img src={link.image} alt={link.title} className="quick-link-image" />
          <div className="quick-link-title">{link.title}</div>
        </a>
      ))}
    </div>
  );
};

export default QuickLinks;