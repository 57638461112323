// src/authConfig.js
export const msalConfig = {
    auth: {
      clientId: "9c78bb81-61ca-4438-a33f-11bc9187a6df", // Replace with your Azure AD App Client ID
      authority: "https://login.microsoftonline.com/46d4e256-b3be-4371-9b3c-480cbc8d7489", // Replace with your Azure AD Tenant ID
      redirectUri: "https://v2.portal.camargue.uk", // Replace with your redirect URI
    },
    cache: {
      cacheLocation: "sessionStorage", // This configuration sets where your cache will be stored
      storeAuthStateInCookie: false, // Set to "true" if using IE11 or Edge
    },
  };
  
  export const loginRequest = {
    scopes: ["user.read"], // Replace with the required scopes
  };
  